import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const RecipePostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p>{description}</p>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

RecipePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const RecipePost = ({ data }) => {
  const { markdownRemark: recipe } = data;

  console.log(recipe);

  return (
    <Layout>
      Recipe here
      {/*<RecipePostTemplate*/}
      {/*  content={post.html}*/}
      {/*  contentComponent={HTMLContent}*/}
      {/*  description={post.frontmatter.description}*/}
      {/*  helmet={*/}
      {/*    <Helmet titleTemplate="%s | Przepis">*/}
      {/*      <title>{`${post.frontmatter.title}`}</title>*/}
      {/*      <meta*/}
      {/*        name="description"*/}
      {/*        content={`Przepis - Veganiola - ${post.frontmatter.title}`}*/}
      {/*      />*/}
      {/*    </Helmet>*/}
      {/*  }*/}
      {/*  tags={post.frontmatter.tags}*/}
      {/*  title={post.frontmatter.title}*/}
      {/*/>*/}
    </Layout>
  );
};

RecipePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default RecipePost;

export const pageQuery = graphql`
  query RecipePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        ingredients
        time
        portion
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: CONSTRAINED)
          }
        }
        color
        category
      }
    }
  }
`;
