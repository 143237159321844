import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import "./styles/tofu-page.sass";
import { HTMLContent } from "../components/Content";
import { Converter } from "showdown";

// eslint-disable-next-line
export const TofuPageTemplate = ({ list }) => {
  const mdConverter = new Converter();
  return (
    <section className="v-tofu">
      <div className="container">
        {(list || []).map(({ name, desc, image, pros, infoList }, i) => {
          return (
            <article key={i} className="v-tofu-item">
              <div className="v-tofu-item-left">
                {name && <h2 className="v-tofu-item-name">{name}</h2>}
                {desc && <p className="v-tofu-item-desc">{desc}</p>}

                <div className="v-tofu-pros-title v-tofu-item-title">
                  Zalety
                </div>
                <div className="v-tofu-pros">
                  {(pros || []).map(({ image, desc }, i) => (
                    <div key={i} className="v-tofu-pros-item">
                      {image && <img src={image.publicURL} alt={desc} />}
                      {desc && <div className="v-tofu-pros-item">{desc}</div>}
                    </div>
                  ))}
                </div>
                <div className="v-tofu-info-list">
                  {(infoList || []).map(({ name, content }, i) => (
                    <div key={i} className="v-tofu-info">
                      {name && (
                        <div className="v-tofu-info-item v-tofu-item-title">
                          {name}
                        </div>
                      )}
                      {content && (
                        <HTMLContent content={mdConverter.makeHtml(content)} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {image && (
                <div className="v-tofu-item-right">
                  <PreviewCompatibleImage
                    imageInfo={image}
                    style={{
                      height: "100%",
                    }}
                  />
                </div>
              )}
            </article>
          );
        })}
      </div>
    </section>
  );
};

TofuPageTemplate.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};

const TofuPage = ({ data, ...props }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <Navbar dark externalPage />
      <TofuPageTemplate list={frontmatter.list} />
    </Layout>
  );
};

TofuPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TofuPage;

export const tofuPageQuery = graphql`
  query TofuPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        list {
          name
          desc
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED)
            }
          }
          pros {
            desc
            image {
              publicURL
            }
          }
          infoList {
            name
            content
          }
        }
      }
    }
  }
`;
